import { getItemDetails } from "api/fetchRequests/requirements";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { createContext, JSX, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router";
import { setChatResponse, setCurrentItem } from "store/reducers/Chat";
import { getUserInfo } from "store/selectors/RegistrationStore";
import { Message } from "store/types/chat";

export const ChatContext = createContext({} as ReturnType<any>);

export const ChatContextProvider = ({ children }: { children: JSX.Element }) => {
  const user = useAppSelector(getUserInfo);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const [messages, setMessages] = useState<Message[]>([]);
  const [isMessageLoading, setIsMessageLoading] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(true);
  const [isFetchLoading, setIsFetchLoading] = useState<boolean>(false);
  const socket = useRef<WebSocket | null>(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (user.id && projectId) {
      socket.current = new WebSocket(
        `wss://${process.env.REACT_APP_AI_DOMAIN_DEV}/ws/chatbot/?user_id=${user.id}&project_id=${projectId}`,
      );

      socket.current.onopen = () => {
        console.log("WebSocket connection established.");
      };

      socket.current.onmessage = (event) => {
        setIsMessageLoading(false);
        const receivedMessage = JSON.parse(event.data);
        const history = receivedMessage.history;
        if (isFirstRender && history) {
          const historyMessages = history.map((message) => ({
            message: message.text,
            self: message.self,
          }));
          setMessages(historyMessages);
          setIsChatLoading(false);
          isFirstRender.current = false;
        }
        const chatResponse = receivedMessage.chatbot_response;
        const systemMessage = receivedMessage.system;
        if (chatResponse) {
          if (typeof chatResponse === "string") {
            const newMessage = {
              message: chatResponse,
              self: false,
            };
            setMessages((state) => [...state, newMessage]);
          } else if (Array.isArray(chatResponse)) {
            const newMessages = chatResponse.map((message) => ({
              message: message.llm_message,
              self: false,
              button: true,
              data: message,
            }));
            setMessages((state) => [...state, ...newMessages]);
          } else {
            dispatch(setChatResponse(chatResponse));
            const newMessage = {
              message: chatResponse.llm_message,
              self: false,
              button: true,
              data: chatResponse,
            };

            setMessages((state) => [...state, newMessage]);
          }
        }
        if (systemMessage) {
          if (typeof systemMessage === "string") {
            const newMessage = {
              message: systemMessage,
              self: false,
            };
            setMessages((state) => [...state, newMessage]);
            setIsMessageLoading(true);
          }
        }
      };
      socket.current.onerror = (error) => {
        const newMessage = {
          message: "Ooops... Sorry, there is something wrong, try again!",
          self: false,
          button: false,
        };
        setMessages((state) => [...state, newMessage]);

        console.error("Error:", error);
      };

      return () => {
        if (socket.current) {
          socket.current.close();
        }
      };
    }
  }, [user, projectId]);

  const fetchItem = async (resData: any) => {
    try {
      setIsFetchLoading(true);
      const res = await getItemDetails(resData.slug, resData.entity, {
        params: { skipInterceptor: true },
      });
      dispatch(setCurrentItem({ ...res }));

      dispatch(setChatResponse(resData));

      setIsFetchLoading(false);
    } catch (error) {
      setIsFetchLoading(false);
    }
  };

  const sendMessage = (messageInput: string) => {
    if (messageInput.trim() !== "") {
      const message = {
        user_prompt: messageInput,
      };
      if (socket.current) {
        socket.current.send(JSON.stringify(message));
        const newMessage = { message: messageInput, self: true };
        setTimeout(() => setIsMessageLoading(true), 100);
        setMessages((state) => [...state, newMessage]);
      }
    }
  };

  const contextValues = useMemo(
    () => ({
      messages,
      isMessageLoading,
      isChatLoading,
      isFetchLoading,
      sendMessage,
      fetchItem,
    }),
    [messages, isMessageLoading, isChatLoading, isFetchLoading],
  );
  return <ChatContext.Provider value={contextValues}>{children}</ChatContext.Provider>;
};
