// import { showNotification } from "@mantine/notifications";
import { ReactComponent as TickIcon } from "icons/tick-notify-icon.svg";
import { notifications } from "@mantine/notifications";
import Icon from "components/Icon";
import { JSX } from "react";

const styles = { title: { fontSize: 14, marginBottom: "3px" } };

const useNotifications = () => {
  const successNotify = ({
    message,
    autoClose,
  }: {
    message: JSX.Element | string;
    autoClose?: number;
  }) => {
    notifications.show({
      title: "Updated successfully!",
      autoClose: autoClose ?? 50000,
      icon: <Icon name="successTickIcon" svgProps={{ width: 18, height: 18 }} />,
      color: "teal",
      message,
      styles,
    });
  };
  const warningNotify = ({
    message,
    hideCloseButton,
  }: {
    message: JSX.Element | string;
    hideCloseButton?: boolean;
  }) => {
    notifications.show({
      title: "Attention!",
      autoClose: 50000,
      icon: <Icon name="alertTriangle" svgProps={{ width: 22, height: 22 }} />,
      color: "white",
      closeButtonProps: { display: hideCloseButton ? "none" : "auto", margin: 0 },
      message,
      styles,
    });
  };
  const errorNotify = (error: string | any) => {
    const message =
      typeof error === "string"
        ? error
        : "response" in error
        ? (Object.values(error.response?.data).flat()[0] as string)
        : error.message;
    notifications.show({
      title: "Oops! You have something wrong!",
      autoClose: 5000,
      color: "red",
      message,
      styles,
    });
  };
  const pushNotify = (title: string, message: string) => {
    notifications.show({
      message: <div dangerouslySetInnerHTML={{ __html: message }} />,
      autoClose: 10000,
      title,
      styles,
    });
  };
  return { successNotify, errorNotify, pushNotify, warningNotify };
};
export const successNotify = ({ message }: { message: JSX.Element | string }) => {
  notifications.show({
    title: "Updated successfully!",
    autoClose: 5000,
    icon: <TickIcon width={18} height={18} />,
    color: "teal",
    message,
    styles,
  });
};

export const errorNotify = (error: string | any) => {
  const message =
    typeof error === "string"
      ? error
      : "response" in error
      ? (Object.values(error.response?.data).flat()[0] as string)
      : error.message;
  notifications.show({
    title: "Oops! You have something wrong!",
    message,
    autoClose: 5000,
    color: "red",
    styles,
  });
};
export const warningNotify = ({ message }: { message: JSX.Element | string }) => {
  notifications.show({
    title: "Attention!",
    message,
    autoClose: 50000,
    icon: <Icon name="alertTriangle" svgProps={{ width: 18, height: 18 }} />,
    color: "orange",
    styles,
  });
};

export default useNotifications;
