import { ReactComponent as logo } from "icons/before-code-logo.svg";
import { ReactComponent as logoSmall } from "icons/logo-small.svg";
import { ReactComponent as projectsIcon } from "icons/projects-icon.svg";
import { ReactComponent as settingsIcon } from "icons/settings-icon.svg";
import { ReactComponent as helpIcon } from "icons/help-icon.svg";
import { ReactComponent as homeIcon } from "icons/house-icon.svg";
import { ReactComponent as chevronUp } from "icons/chevron-up.svg";
import { ReactComponent as chevronDown } from "icons/chevron-down.svg";
import { ReactComponent as plusIcon } from "icons/plus-icon.svg";
import { ReactComponent as plusIconMiddle } from "icons/plus-icon-middle.svg";
import { ReactComponent as smallPlusIcon } from "icons/small-plus-icon.svg";
import { ReactComponent as plusIconWhite } from "icons/plus-icon-white.svg";
import { ReactComponent as sidebarArrows } from "icons/sidebar-arrows.svg";
import { ReactComponent as loupeIcon } from "icons/loupe-icon.svg";
import { ReactComponent as toolbarArrow } from "icons/toolbar-arrow.svg";
import { ReactComponent as handStop } from "icons/hand-stop.svg";
import { ReactComponent as uploadPhotoToolbar } from "icons/upload-photo-toolbar.svg";
import { ReactComponent as uploadPhotoToolbarNew } from "icons/upload-icon-toolbar-new.svg";
import { ReactComponent as imagePicture } from "icons/image-picture.svg";
import { ReactComponent as logoutIcon } from "icons/logout-icon.svg";
import { ReactComponent as featureLevelIcon } from "icons/feature-level-icon.svg";
import { ReactComponent as groupLevelIcon } from "icons/group-level-icon.svg";
import { ReactComponent as screenLevelIcon } from "icons/screen-level-icon.svg";
import { ReactComponent as elementLevelIcon } from "icons/element-level-icon.svg";
import { ReactComponent as chevronLeft } from "icons/chevron-left.svg";
import { ReactComponent as chevronRight } from "icons/chevron-right.svg";
import { ReactComponent as menuTickIcon } from "icons/menu-tick-icon.svg";
import { ReactComponent as successTickIcon } from "icons/tick-notify-icon.svg";
import { ReactComponent as navbarArrowDown } from "icons/navbar-arrow-down.svg";
import { ReactComponent as navbarArrowRight } from "icons/navbar-arrow-right.svg";
import { ReactComponent as openSidebarIcon } from "icons/mark-element-icon.svg";
import { ReactComponent as lowestPriorityIcon } from "icons/lowest-priority-icon.svg";
import { ReactComponent as lowPriorityIcon } from "icons/low-priority-icon.svg";
import { ReactComponent as mediumPriorityIcon } from "icons/medium-priority-icon.svg";
import { ReactComponent as highPriorityIcon } from "icons/high-priority-icon.svg";
import { ReactComponent as highestPriorityIcon } from "icons/highest-priority-icon.svg";
import { ReactComponent as markElementIcon } from "icons/mark-element-new-icon.svg";
import { ReactComponent as alertSelectIcon } from "icons/alert-select-icon.svg";
import { ReactComponent as buttonSelectIcon } from "icons/buttonSelectIcon.svg";
import { ReactComponent as checkboxSelectIcon } from "icons/checkbox-select-icon.svg";
import { ReactComponent as datePickerSelectIcon } from "icons/datepicker-select-icon.svg";
import { ReactComponent as dropdownSelectIcon } from "icons/dropdown-select-icon.svg";
import { ReactComponent as iconLogo } from "icons/icon-logo.svg";
import { ReactComponent as maximizeModal } from "icons/maximize-modal.svg";
import { ReactComponent as minimizeModal } from "icons/minimize-modal.svg";
import { ReactComponent as verticalArrowsIcon } from "icons/vertical-arrows-icon.svg";
import { ReactComponent as threeDotsAlign } from "icons/three-dots-align-icon.svg";
import { ReactComponent as copyEmail } from "icons/copy-email-icon.svg";
import { ReactComponent as crossIcon } from "icons/cross-icon.svg";
import { ReactComponent as crossIconBig } from "icons/cross-icon-big.svg";
import { ReactComponent as menuIcon } from "icons/project-card-menu.svg";
import { ReactComponent as terminalIcon } from "icons/terminalIcon.svg";
import { ReactComponent as updatedIcon } from "icons/updated-icon.svg";
import { ReactComponent as horizontalOrientation } from "icons/horizontal-orientation-icon.svg";
import { ReactComponent as verticalOrientation } from "icons/vertical-orientation-icon.svg";
import { ReactComponent as uploadDesignIcon } from "icons/upload-desing-icon.svg";
import { ReactComponent as trashIcon } from "icons/trash-icon.svg";
import { ReactComponent as duplicateIcon } from "icons/duplicate-icon.svg";
import { ReactComponent as arrowRight } from "icons/arrow-right.svg";
import { ReactComponent as uploadFigmaDesignIcon } from "icons/upload-figma-design-icon.svg";
import { ReactComponent as alertTriangle } from "icons/alert-triangle.svg";
import { ReactComponent as alertTriangleWhite } from "icons/alert-triangle-white.svg";
import { ReactComponent as alertTrianglePopover } from "icons/alert-triangle-popover.svg";
import { ReactComponent as externalLinkIcon } from "icons/external-link-icon.svg";
import { ReactComponent as mergeIcon } from "icons/merge-icon.svg";
import { ReactComponent as fileUploaderIcon } from "icons/fileUploaderIcon.svg";
import { ReactComponent as htmlIcon } from "icons/htmlIcon.svg";
import { ReactComponent as imageIcon } from "icons/imageIcon.svg";
import { ReactComponent as linkIcon } from "icons/linkIcon.svg";
import { ReactComponent as mapIcon } from "icons/mapIcon.svg";
import { ReactComponent as multilineInputIcon } from "icons/multilineInputIcon.svg";
import { ReactComponent as shapeIcon } from "icons/shapeIcon.svg";
import { ReactComponent as sliderInputIcon } from "icons/sliderInputIcon.svg";
import { ReactComponent as squareDotIcon } from "icons/squareDotIcon.svg";
import { ReactComponent as textIcon } from "icons/textIcon.svg";
import { ReactComponent as videoIcon } from "icons/videoIcon.svg";
import { ReactComponent as projectViewIcon } from "icons/project-view-icon.svg";
import { ReactComponent as boardViewIcon } from "icons/board-view-icon.svg";
import { ReactComponent as estimationIcon } from "icons/estimation-icon.svg";
import { ReactComponent as eyeIcon } from "icons/eye-icon.svg";
import { ReactComponent as addAssigneeIcon } from "icons/add-assignee-icon.svg";
import { ReactComponent as ungroupedScreensIcon } from "icons/ungrouped-screens-icon.svg";
import { ReactComponent as subtaskLevelIcon } from "icons/task-level-icon.svg";
import { ReactComponent as toDoIcon } from "icons/to-do-icon.svg";
import { ReactComponent as inProgressIcon } from "icons/in-progress-icon.svg";
import { ReactComponent as forApprovalIcon } from "icons/approve-icon.svg";
import { ReactComponent as doneIcon } from "icons/done-icon.svg";
import { ReactComponent as orgSettingsIcon } from "icons/organization-settings.svg";
import { ReactComponent as boldIcon } from "icons/bold-icon.svg";
import { ReactComponent as italicIcon } from "icons/italic-icon.svg";
import { ReactComponent as strikeIcon } from "icons/crossed-icon.svg";
import { ReactComponent as linkToolbarIcon } from "icons/link-icon.svg";
import { ReactComponent as markedListIcon } from "icons/marked-list-icon.svg";
import { ReactComponent as unmarkedListIcon } from "icons/unmarked-list-icon.svg";
import { ReactComponent as blockQuoteIcon } from "icons/line-list-icon.svg";
import { ReactComponent as sendCommentIcon } from "icons/send-comment-icon.svg";
import { ReactComponent as inviteWarningIcon } from "icons/invite-worning-icon.svg";
import { ReactComponent as datePickerIcon } from "icons/date-picker-icon.svg";
import { ReactComponent as workspaceTickIcon } from "icons/workspace-tick-icon.svg";
import { ReactComponent as timeTrackingIcon } from "icons/time-tracking-icon.svg";
import { ReactComponent as triangleChevronIcon } from "icons/triangle-chevron-icon.svg";
import { ReactComponent as magicWandIcon } from "icons/magic-wand-icon.svg";
import { ReactComponent as filtersIcon } from "icons/filters-icon.svg";
import { ReactComponent as bookmarkIcon } from "icons/bookmark-icon.svg";
import { ReactComponent as bugLevelIcon } from "icons/bug-icon.svg";
import { ReactComponent as uploadIcon } from "icons/upload-pictures.svg";
import { ReactComponent as uploadFigmaIcon } from "icons/upload-figma.svg";
import { ReactComponent as uploadPhotoIcon } from "icons/upload-photo-icon.svg";
import { ReactComponent as noAvatarIcon } from "icons/no-avatar-icon.svg";
import { ReactComponent as backlogIcon } from "icons/backlog-icon.svg";
import { ReactComponent as jiraExportIcon } from "icons/jira-export-icon.svg";
import { ReactComponent as pdfExportIcon } from "icons/pdf-export-icon.svg";
import { ReactComponent as excelExportIcon } from "icons/excel-export-icon.svg";
import { ReactComponent as exportIcon } from "icons/export-icon.svg";
import { ReactComponent as nonFunctionalIcon } from "icons/non-functional-icon.svg";
import { ReactComponent as chatIcon } from "icons/chat-icon.svg";
import { ReactComponent as chatLogo } from "icons/chat-logo.svg";
import { ReactComponent as chatUndo } from "icons/undo-icon.svg";
import { ReactComponent as chatSend } from "icons/chat-send-icon.svg";
import { ReactComponent as chatCloseIcon } from "icons/chat-close-icon.svg";
import { ReactComponent as grayArrowIcon } from "icons/gray-arrow-icon.svg";
import { ReactComponent as copyIcon } from "icons/copy-icon.svg";

const svgIcons: { [name: string]: any } = {
  jiraExportIcon,
  alertTriangleWhite,
  successTickIcon,
  copyIcon,
  grayArrowIcon,
  chatLogo,
  chatSend,
  chatUndo,
  chatCloseIcon,
  nonFunctionalIcon,
  exportIcon,
  logoSmall,
  pdfExportIcon,
  excelExportIcon,
  projectsIcon,
  noAvatarIcon,
  backlogIcon,
  uploadIcon,
  uploadFigmaIcon,
  settingsIcon,
  helpIcon,
  homeIcon,
  trashIcon,
  plusIcon,
  plusIconMiddle,
  smallPlusIcon,
  sidebarArrows,
  loupeIcon,
  plusIconWhite,
  toolbarArrow,
  handStop,
  uploadPhotoToolbar,
  uploadPhotoToolbarNew,
  uploadDesignIcon,
  uploadFigmaDesignIcon,
  duplicateIcon,
  imagePicture,
  logoutIcon,
  featureLevelIcon,
  groupLevelIcon,
  screenLevelIcon,
  elementLevelIcon,
  arrowRight,
  chevronUp,
  chevronDown,
  chevronLeft,
  chevronRight,
  menuTickIcon,
  logo,
  navbarArrowDown,
  navbarArrowRight,
  openSidebarIcon,
  markElementIcon,
  lowestPriorityIcon,
  lowPriorityIcon,
  mediumPriorityIcon,
  highPriorityIcon,
  highestPriorityIcon,
  alertSelectIcon,
  buttonSelectIcon,
  checkboxSelectIcon,
  datePickerSelectIcon,
  dropdownSelectIcon,
  verticalArrowsIcon,
  iconLogo,
  maximizeModal,
  minimizeModal,
  threeDotsAlign,
  copyEmail,
  crossIcon,
  crossIconBig,
  menuIcon,
  terminalIcon,
  updatedIcon,
  horizontalOrientation,
  verticalOrientation,
  alertTriangle,
  alertTrianglePopover,
  externalLinkIcon,
  mergeIcon,
  fileUploaderIcon,
  htmlIcon,
  imageIcon,
  linkIcon,
  mapIcon,
  multilineInputIcon,
  shapeIcon,
  sliderInputIcon,
  squareDotIcon,
  textIcon,
  videoIcon,
  addAssigneeIcon,
  projectViewIcon,
  boardViewIcon,
  estimationIcon,
  eyeIcon,
  ungroupedScreensIcon,
  subtaskLevelIcon,
  toDoIcon,
  inProgressIcon,
  forApprovalIcon,
  doneIcon,
  orgSettingsIcon,
  boldIcon,
  italicIcon,
  strikeIcon,
  linkToolbarIcon,
  markedListIcon,
  unmarkedListIcon,
  blockQuoteIcon,
  sendCommentIcon,
  inviteWarningIcon,
  datePickerIcon,
  workspaceTickIcon,
  timeTrackingIcon,
  triangleChevronIcon,
  magicWandIcon,
  filtersIcon,
  bookmarkIcon,
  bugLevelIcon,
  uploadPhotoIcon,
  chatIcon,
};

export default svgIcons;
