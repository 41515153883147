import React from "react";
import classNames from "classnames";
import { Flex, Text } from "@mantine/core";
import svgIcons from "./svgIcons";
import styles from "./styles.module.css";

interface IconProps {
  name: string;
  highlight?: boolean;
  highlightFill?: boolean;
  hover?: boolean;
  hoverFill?: boolean;
  active?: boolean;
  activeFill?: boolean;
  label?: string;
  svgProps?: React.SVGProps<SVGSVGElement>;
  className?: string;
  isNavbarIcon?: boolean;
  hidden?: boolean;
  error?: boolean;
  errorFill?: boolean;
  untransparent?: boolean;
  disabled?: boolean;
  styleProps?: any;
}

const Icon = ({
  name,
  active,
  activeFill,
  label,
  svgProps,
  hover,
  hoverFill,
  className,
  isNavbarIcon,
  hidden,
  error,
  errorFill,
  untransparent,
  disabled,
  highlight,
  highlightFill,
  styleProps,
}: IconProps) => {
  if (name in svgIcons) {
    const Icon = svgIcons[name];
    return (
      <Flex
        direction="column"
        justify="center"
        align="center"
        className={classNames(
          hover && styles.hover,
          active && styles.active,
          activeFill && styles.activeFill,
          hoverFill && styles.hoverFill,
          isNavbarIcon && active && styles.navbarIcon,
          error && styles.error,
          errorFill && styles.errorFill,
          untransparent && styles.untransparent,
          disabled && styles.disabled,
          highlight && styles.highlight,
          highlightFill && styles.highlightFill,
        )}
        style={{ visibility: hidden ? "hidden" : "visible", ...styleProps }}
      >
        <Icon className={classNames(className)} {...svgProps} />
        {label && (
          <Text fz="10px" className={styles.text}>
            {label}
          </Text>
        )}
      </Flex>
    );
  }
  return <></>;
};

export default Icon;
