import { createSlice } from "@reduxjs/toolkit";
import { EMPTY_CHAT_RESPONSE } from "constants/chat";
import { ChatState } from "store/types/chat";

const initialState: ChatState = {
  isOpen: false,
  isModalOpen: false,
  currentItem: null,
  field: "",
  messages: [],
  isLoading: false,
  chatResponse: EMPTY_CHAT_RESPONSE,
};

export const chatSlice = createSlice({
  name: "Chat",
  initialState,
  reducers: {
    setIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setMessages: (state, { payload }) => {
      state.messages = [...state.messages, payload];
    },
    setIsModalOpen: (state, { payload }) => {
      state.isModalOpen = payload;
    },
    setCurrentItem: (state, { payload }) => {
      state.currentItem = payload;
    },
    setChatResponse: (state, { payload }) => {
      state.chatResponse = payload;
    },
    setField: (state, { payload }) => {
      state.field = payload;
    },
  },
});

export const {
  setIsOpen,
  setIsModalOpen,
  setCurrentItem,
  setIsLoading,
  setMessages,
  setField,
  setChatResponse,
} = chatSlice.actions;

export default chatSlice.reducer;
