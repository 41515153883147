import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getProjectNavigation } from "api/fetchRequests/project";
import { getItemDetails } from "api/fetchRequests/requirements";
import { ENTITY_TYPES } from "constants/projects";
import { RequirementsState } from "store/types/requirements";

const initialState: RequirementsState = {
  group: null,
  screen: null,
  element: null,
  isLoading: true,
  isNavLoading: true,
  error: null,
  markElement: false,
  showElements: true,
  deleteModalState: false,
  currentDeleteElement: null,
  elementModalState: false,
  newElement: null,
  scale: 1,
  taskParentScreen: null,
  openedItems: [],
  navigationItems: [],
};

export const fetchRequirements = createAsyncThunk(
  "fetchRequirements",
  async ({ slug, type }: { slug: string; type: string }) => {
    try {
      const res = await getItemDetails(slug, type);
      if (type === ENTITY_TYPES.screen) {
        const imageObj = new Image();
        imageObj.src = res.image;

        const newScreen = {
          ...res,
          imageObj,
        };
        // const currentGroup =
        return { screen: newScreen };
      }
      if (type === ENTITY_TYPES.element) {
        return { element: res };
      }
    } catch (err) {
      console.log(err);
    }
  },
);
export const fetchNavigation = createAsyncThunk(
  "fetchNavigation",
  async ({ projectId }: { projectId: number }) => {
    try {
      const res = await getProjectNavigation(projectId);
      const openedItems = res.data.items.map((item) => item.id);
      return { navigationItems: res.data.items, openedItems };
    } catch (err) {
      console.log(err);
      return { navigationItems: [] };
    }
  },
);
export const requirementsSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    setActiveScreen: (state, { payload }) => {
      if (payload) {
        const imageObj = new Image();
        imageObj.src = payload.image;

        const newScreen = {
          ...payload,
          imageObj,
        };
        // const currentGroup =
        state.screen = newScreen;
        return;
      }
      state.screen = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setOpenedItems: (state, { payload }) => {
      if (state.openedItems.includes(payload)) {
        const newItems = state.openedItems.filter((el) => el !== payload);
        state.openedItems = newItems;
      } else {
        state.openedItems = [...state.openedItems, payload];
      }
    },
    setScale: (state, { payload }) => {
      state.scale = payload;
    },
    setElementModalState: (state, { payload }) => {
      state.elementModalState = payload;
    },
    setNewElement: (state, { payload }) => {
      state.newElement = payload;
    },
    setDeleteModalState: (state, { payload }) => {
      if (payload.element) {
        state.currentDeleteElement = payload.element;
      } else {
        state.currentDeleteElement = null;
      }
      state.deleteModalState = payload.state;
    },
    setActiveElement: (state, { payload }) => {
      state.element = payload;
    },
    setMarkElement: (state, { payload }) => {
      state.markElement = payload;
    },
    setShowElements: (state, { payload }) => {
      state.showElements = payload;
    },
    setNavigationItems: (state, { payload }) => {
      state.navigationItems = payload;
    },
    clearRequirementsState: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRequirements.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRequirements.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload?.screen) {
          state.screen = payload.screen;
        }
        if (payload?.element) {
          state.element = payload.element;
        }
      })
      .addCase(fetchRequirements.rejected, (state, action) => {
        state.error = action.error.message || "";
        state.isLoading = false;
      })
      .addCase(fetchNavigation.pending, (state) => {
        state.isNavLoading = true;
      })
      .addCase(fetchNavigation.fulfilled, (state, { payload }) => {
        state.isNavLoading = false;
        state.navigationItems = payload.navigationItems;
        state.openedItems = payload.openedItems;
      })
      .addCase(fetchNavigation.rejected, (state, action) => {
        state.error = action.error.message || "";
        state.isNavLoading = false;
      });
  },
});

export const {
  clearRequirementsState,
  setActiveScreen,
  setActiveElement,
  setDeleteModalState,
  setElementModalState,
  setIsLoading,
  setMarkElement,
  setNewElement,
  setScale,
  setShowElements,
  setOpenedItems,
  setNavigationItems,
} = requirementsSlice.actions;

export default requirementsSlice.reducer;
