import { createSlice } from "@reduxjs/toolkit";
import { SidebarState } from "store/types/sidebar";

const initialState: SidebarState = {
  sidebarElement: null,
  slugForFetch: "",
  fetchEntity: "",
  isOpen: false,
  statuses: [],
  isLoading: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarItem: (state, { payload }) => {
      state.sidebarElement = payload;
    },
    setSlug: (state, { payload }) => {
      state.slugForFetch = payload.slug;
      state.fetchEntity = payload.entity;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    toggleSidebar: (state, { payload }) => {
      state.isOpen = payload;
    },
    setStatuses: (state, { payload }) => {
      state.statuses = payload;
    },
    clearSidebarState: () => initialState,
  },
});

export const {
  clearSidebarState,
  setSidebarItem,
  toggleSidebar,
  setStatuses,
  setSlug,
  setIsLoading,
} = sidebarSlice.actions;

export default sidebarSlice.reducer;
