import { API } from "api/apiURLs";
import { customFetcher } from "api/fetcher";
import { AxiosRequestConfig } from "axios";

const fetcherApplicationJson = customFetcher("application/json");
const fetcherMultipartData = customFetcher("multipart/form-data");

export const getItemDetails = async (slug: string, type: string, options?: AxiosRequestConfig) => {
  const Api: { [key: string]: (id: string) => string } = {
    group: API.groupDetails,
    screen: API.screenDetails,
    element: API.elementDetails,
  };
  const res = await fetcherApplicationJson.get(Api[`${type}`](slug), options);
  return res.data;
};

export const generateReq = async (slug: string, field: string) => {
  const res = await fetcherApplicationJson.post(API.generateScreenReq(slug), { field });
  return res;
};

export const getTimeLog = async (slug: string, type: string) => {
  const Api: { [key: string]: (type: string) => string } = {
    group: API.getGroupTimeLog,
    screen: API.getScreenTimeLog,
    element: API.getElementTimeLog,
  };
  const res = await fetcherApplicationJson.get(Api[`${type}`](slug));
  return res.data;
};

export const editTimeLog = async (id: number, data: any) => {
  const res = await fetcherApplicationJson.patch(API.editDeleteTimeLog(id), data);
  return res;
};
export const deleteTimeLog = async (id: number) => {
  const res = await fetcherApplicationJson.delete(API.editDeleteTimeLog(id));
  return res;
};
export const uploadAttachments = async (slug: string, data: any) => {
  const res = await fetcherMultipartData.post(API.uploadAttachments(slug), data);
  return res;
};

export const deleteAttachment = async (id: number) => {
  const res = await fetcherApplicationJson.delete(API.deleteAttachment(id));
  return res;
};
